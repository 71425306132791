import { FC, HTMLProps } from "react";
import styles from "./Feedback.module.scss";
import classNames from "classnames";
import { useTranslation } from "next-i18next";

import { useAppProvider } from "../../utils/appProvider";
import OutlinedBtn from "../Buttons/OutlinedButton";
import Check from "./check.svg";

export type Props = { addclasses?: string[] } & HTMLProps<HTMLDivElement>;

const Feedback: FC<Props> = (props) => {
  const { addclasses } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );
  const { showFeedback, setShowFeedback } = useAppProvider();
  const { t } = useTranslation("common");
  const handleClose = () => {
    setShowFeedback(false);
  };

  if (!showFeedback) return null;

  return (
    <>
      <div className={className} {...props}>
        <Check className={styles["check"]} />
        <h3 className="H3">{t("request_thanks")}</h3>
        <span className="T1">{t("request_text")}</span>
        <OutlinedBtn
          title={t("request_btn")}
          handleClick={handleClose}
          addclasses={[styles["btn"]]}
        />
      </div>
      <div className={styles["tint"]}></div>
    </>
  );
};

export default Feedback;
