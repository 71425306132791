import { ReactNode, useEffect } from "react";
import React from "react";

import BreadCrumbs from "../BreadCrumbs";
import Feedback from "../Feedback";
import Footer from "../Footer";
import Sidebar from "../Navigation/Sidebar";
import Cookie from "../Notification/Cookie";
import EnableJS from "../Notification/EnableJS";

import styles from "./PageWrapper.module.scss";
import BackToTop from "../BackToTop/BackToTop";
import { useAppProvider } from "../../utils/appProvider";
import { adjustHeight } from "../../utils/functions";

type Props = {
  children: ReactNode;
};

const PageWrapper = (props: Props) => {
  const { setSubmenuIndex } = useAppProvider();

  useEffect(() => {
    // fix 100vh on mobile devices
    // https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
    window.addEventListener("resize", adjustHeight);
    adjustHeight();
  }, []);

  return (
    <div className={styles["container"]}>
      <Feedback />
      <Cookie />
      <EnableJS />
      <BackToTop />
      <div
        className={`${styles["content"]}`}
        onMouseEnter={() => setSubmenuIndex(null)}
      >
        <BreadCrumbs />
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

export default PageWrapper;
