import { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import SharingEn from "../../public/imgs/sharing-en.png";
import SharingRu from "../../public/imgs/sharing-ru.png";

type Props = {
  desc?: string;
  title?: string;
  metaTitle?: string;
  children?: React.ReactNode;
};

const CustomHead = ({ title, metaTitle, desc, children }: Props) => {
  const { i18n } = useTranslation();
  const { asPath } = useRouter();
  const sharingImg = i18n.language === "ru" ? SharingRu : SharingEn;
  const statusBarColor = asPath === "/" ? "#263b47" : "#fff";
  const defaultDesc =
    i18n.language === "ru"
      ? "Разработка программных продуктов в области компьютерного зрения, видеоаналитики"
      : "Development of software products in the field of computer vision, video analytics";

  useEffect(() => {
    (window as any).ym(
      process.env.NEXT_PUBLIC_YANDEX_METRIC_ID,
      "hit",
      window.location.href
    );
  }, []);
  return (
    <Head>
      <title>{title ? `Tevian. ${title}` : "Tevian"}</title>
      <meta name="description" content={desc || defaultDesc} />
      <meta property="og:image" content={sharingImg.src} />
      <meta property="og:title" content={metaTitle || title} />
      <meta
        property="og:url"
        content={`${process.env.NEXT_PUBLIC_API_URL}${asPath}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:description" content={desc || defaultDesc} />
      <meta name="theme-color" content={statusBarColor} />
      {children}
      <link
        rel="alternate"
        hrefLang="ru"
        href={`${process.env.NEXT_PUBLIC_API_URL}/ru${asPath}`}
      />
      <link
        rel="alternate"
        hrefLang={asPath === "/" ? "x-default" : "en"}
        href={`${process.env.NEXT_PUBLIC_API_URL}${asPath}`}
      />

      <link rel="icon" href="/icons/favicon.ico" sizes="32x32" />
      <link rel="icon" href="/icons/icon.svg" type="image/svg+xml" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
      <link rel="manifest" href="/icons/manifest.webmanifest" />
    </Head>
  );
};

export default CustomHead;
