import { ReactNode, useLayoutEffect } from "react";
import gsap from "gsap";

import OutlinedBtn from "../Buttons/OutlinedButton";

import styles from "./Notification.module.scss";

type Props = {
  text: string | ReactNode;
  btnName: string;
  checkIfShow: () => boolean; // function since local storage is available only in useEffect
  onClick?: () => void;
};

const Notification = ({ text, btnName, checkIfShow, onClick }: Props) => {
  const hideNotification = () => {
    const timeline = gsap.timeline();
    gsap.context(() => {
      timeline.fromTo(`.${styles["container"]}`, { y: "0" }, { y: "100%" });
      timeline.set(`.${styles["container"]}`, { display: "none" });
    });
  };

  const handleClick = () => {
    onClick && onClick();
    hideNotification();
  };

  useLayoutEffect(() => {
    let ctx: gsap.Context | null = null;
    const show = checkIfShow();
    if (show) {
      const timeline = gsap.timeline().delay(2);
      gsap.set(`.${styles["container"]}`, { y: "100%" });
      gsap.set(`.${styles["container"]}`, { display: "flex" });
      ctx = gsap.context(() => {
        timeline.fromTo(`.${styles["container"]}`, { y: "100%" }, { y: 0 });
      });
    }
    return () => {
      ctx?.kill();
    };
  }, []);

  return (
    <div className={styles["container"]}>
      <p className="T1">{text}</p>
      <OutlinedBtn title={btnName} handleClick={handleClick} />
    </div>
  );
};

export default Notification;
