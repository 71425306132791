import { FC, useEffect, useLayoutEffect, useState } from "react";
import styles from "./BackToTop.module.scss";
import classNames from "classnames";
import { gsap } from "gsap";
import Shevron from "./chevron_up.svg";
import { screenSizes } from "../../utils/constants";

gsap.config({ nullTargetWarn: false });

export type Props = { addclasses?: string[] };

const BackToTop: FC<Props> = ({ addclasses }) => {
  const [show, setShow] = useState(false);
  const mm = gsap.matchMedia();
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const toggleShow = () => {
    const { scrollTop, clientHeight } = document.documentElement;
    if (scrollTop - clientHeight > 200) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useLayoutEffect(() => {
    const timeline = gsap.timeline();
    const byClass = `.${styles["container"]}`;
    mm.add(screenSizes, (context) => {
      const { isSmall } = context.conditions as any;
      if (isSmall) return;
      if (show) {
        timeline.set(byClass, { display: "flex" });
        timeline.fromTo(
          byClass,
          {
            y: "200%",
          },
          { y: 0 }
        );
      } else {
        timeline.fromTo(
          byClass,
          {
            y: 0,
          },
          { y: "200%" }
        );
        timeline.set(byClass, { display: "none" });
      }
    });
    return () => mm.kill();
  }, [show]);

  useEffect(() => {
    document.addEventListener("scroll", toggleShow);
  }, []);

  return (
    <div className={className} onClick={scrollToTop}>
      <Shevron />
    </div>
  );
};

export default BackToTop;
