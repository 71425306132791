import { useTranslation } from "next-i18next";

import styles from "./Notification.module.scss";

const EnableJS = () => {
  const { t } = useTranslation("common");
  return (
    <noscript>
      <div
        className={styles["container"]}
        style={{ display: "block", zIndex: 10000 }}
      >
        <p className="T1">{t("enable_js")}</p>
      </div>
    </noscript>
  );
};

export default EnableJS;
