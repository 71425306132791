import Link from "next/link";
import { useTranslation } from "next-i18next";

import Notification from "./Notification";
import { isLocalStorageEnabled } from "../../utils/functions";
import { useAppProvider } from "../../utils/appProvider";

const Cookie = () => {
  const { t } = useTranslation("common");
  const { cookiesAreAgreed, setCookiesAreAgreed } = useAppProvider();
  const text = (
    <>
      {t("cookie")} <Link href="/terms">{t("cookie_link")}</Link>
    </>
  );
  const checkIfShow = () => {
    if (isLocalStorageEnabled())
      return (
        localStorage && !(localStorage.getItem("cookies_agreed") === "true")
      );
    return !cookiesAreAgreed;
  };

  return (
    <Notification
      text={text}
      btnName={t("got_it")}
      checkIfShow={checkIfShow}
      onClick={() => {
        if (isLocalStorageEnabled()) {
          localStorage.setItem("cookies_agreed", "true");
        } else {
          setCookiesAreAgreed(true);
        }
      }}
    />
  );
};

export default Cookie;
