import { FC, HTMLProps } from "react";
import styles from "./MaxWidth.module.scss";
import classNames from "classnames";

export type Props = { addclasses?: string[] } & HTMLProps<HTMLDivElement>;

const MaxWidth: FC<Props> = (props) => {
  const { addclasses, children } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  return (
    <div className={className} {...props}>
      <div className={styles["limit"]}>{children}</div>
    </div>
  );
};

export default MaxWidth;
