import classNames from "classnames";
import Link from "next/link";
import { NextRouter, useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { getCaseByName } from "../../assets/data/cases";
import MaxWidth from "../MaxWidth";

import styles from "./BreadCrumbs.module.scss";

const getSubdirInfo = (router: NextRouter, subdirPath: string, t: any) => {
  if (!subdirPath) return { name: "", link: "" };
  switch (subdirPath) {
    case "[s_name]":
      const { s_name } = router.query;
      return {
        name: t(`solutions:name.${s_name}`),
        link: `/solution/${s_name}`,
      };
    case "[p_name]":
      const { p_name } = router.query;
      return {
        name: t(`products:name.${p_name}`),
        link: `/product/${p_name}`,
      };
    case "[c_name]":
      const { c_name } = router.query;
      const case_obj = getCaseByName(c_name as string);
      return {
        name: t(`cases:${c_name}.name`),
        link: `/case/${c_name}`,
        customDirPath: `/solution/${case_obj?.solutionName || ""}`,
        customDirName: t(`solutions:name.${case_obj?.solutionName}`) || "",
        category: "cases",
      };
    case "[n_id]":
      const { n_id } = router.query;
      return {
        name: `Новость ${n_id}`,
        link: `/news/${n_id}`,
        category: "news",
      };
    default:
      return { name: "", link: "", categoty: "" };
  }
};

const BreadCrumbs = () => {
  const router = useRouter();
  const { t } = useTranslation(["common", "products", "solutions", "cases"]);
  const paths = router.pathname.split("/");
  let dirPath = paths[1];
  let dirName =
    dirPath == "404" || dirPath == "500" ? null : t(`bread_crumbs.${dirPath}`);
  const subdir = getSubdirInfo(router, paths[2], t);

  const linkClassName = classNames([styles["link"], "T2"]);

  if (!dirPath) return null;

  return (
    <MaxWidth>
      <div className={`${styles["container"]}`}>
        <Link href="/" className={linkClassName}>
          {t("main_page")}
        </Link>
        {dirName && (
          <Link
            href={`/${subdir.customDirPath || dirPath}`}
            key={dirPath}
            className={linkClassName}
          >
            {subdir.customDirName || dirName}
          </Link>
        )}
        {subdir.name && (
          <Link href={subdir.link} key={dirPath} className={linkClassName}>
            {subdir.name}
          </Link>
        )}
      </div>
    </MaxWidth>
  );
};

export default BreadCrumbs;
