import { StaticImageData } from "next/image";

import CaseBank from "../../public/imgs/cases/case-bank-big.png";
import CaseShoes from "../../public/imgs/cases/case-bank-big.png";
import CaseBankPreview from "../../public/imgs/cases/case-bank-small.png";
import CaseShoesPreview from "../../public/imgs/cases/case-shoes-small.png";

export type Case = {
  id: number;
  imgSrc: StaticImageData;
  previewImg: StaticImageData;
  deadline: number;
  year: number;
  products: string[];
  href: string;
  solutionName: string;
};

export const bank: Case = {
  id: 1,
  imgSrc: CaseBank,
  previewImg: CaseBankPreview,
  year: 2020,
  deadline: 4,
  products: ["TEVIAN FACE\u00A0SDK", "TEVIAN FACE\u00A0CAPTURE"],
  href: "bank",
  solutionName: "financial-sector",
};
export const shoeStore: Case = {
  id: 2,
  imgSrc: CaseShoes,
  previewImg: CaseShoesPreview,
  year: 2022,
  deadline: 5,
  products: ["TEVIAN FACE\u00A0SDK", "TEVIAN FACE\u00A0CAPTURE"],
  href: "shoe-store",
  solutionName: "retail",
};

const case_list: { [key: string]: Case } = {
  bank,
  "shoe-store": shoeStore,
};

export const getCaseByName = (c_name: string) => {
  return case_list[c_name];
};
