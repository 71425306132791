import { FC, HTMLProps } from "react";
import styles from "./Footer.module.scss";
import classNames from "classnames";
import MaxWidth from "../MaxWidth/MaxWidth";
import GridContainer from "../GridContainer/GridContainer";
import Logo from "../Logo/Logo";
import { products } from "../../assets/data/products";
import { solutions } from "../../assets/data/solutions";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import Image from "next/image";
import NIST from "../../public/imgs/NIST_logo.png";
import RUSInnovation from "../../public/imgs/logo_fsi.svg?url";
import { useRouter } from "next/router";

export type Props = { addclasses?: string[] } & HTMLProps<HTMLDivElement>;

const Footer: FC<Props> = (props) => {
  const { addclasses } = props;
  const router = useRouter();
  const { t, i18n } = useTranslation(["products", "solutions", "common"]);
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const rusInnovationClass = classNames([styles["top-member"], styles["link"]]);
  const showSOUT = router.pathname === "/about" && i18n.language === "ru";

  const handleRusInnovationClick = () => {
    window.open("http://www.fasie.ru/", "_blank");
  };

  const onLogoClick = () => {
    if (router.pathname === "/") {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      router.push("/");
    }
  };

  const registerEmailCopy = () => {
    (window as any).ym(
      process.env.NEXT_PUBLIC_YANDEX_METRIC_ID,
      "reachGoal",
      "email_copy"
    );
  };

  return (
    <>
      <MaxWidth addclasses={[styles["wrapper"]]}>
        <GridContainer addclasses={[className]}>
          <Logo addclasses={[styles["logo"]]} handleClick={onLogoClick} />
          <span className={styles["address"]}>
            {t("common:address_1-footer")},
            <br />
            {t("common:address_2-footer")}
            <br />
            <a href="mailto:info@tevian.ai" onCopy={registerEmailCopy}>
              info@tevian.ai
            </a>
          </span>
          <div className={styles["links"]}>
            <div className={styles["link-block"]}>
              <Link href="/product">{t("common:products")}</Link>
              {products.map((p) => (
                <Link href={`/product/${p.href}`} key={p.href}>
                  {t(`products:name.${p.href}`)}
                </Link>
              ))}
            </div>
            <div className={styles["link-block"]}>
              <Link href="/solution">{t("common:solutions")}</Link>
              {solutions.map((s) => (
                <Link href={`/solution/${s.href}`} key={s.href}>
                  {t(`solutions:name.${s.href}`)}
                </Link>
              ))}
              <Link href="/customer">{t("common:customer")}</Link>
            </div>
            <div className={styles["link-block"]}>
              <Link href="/about">{t("common:about_us")}</Link>
              <Link href="/careers">{t("common:career")}</Link>
            </div>
            <div className={styles["link-block"]}>
              <Link href="/partners">{t("common:for_partners")}</Link>
              <Link href="/contacts">{t("common:contacts")}</Link>
            </div>
          </div>
          <div className={styles["top-list"]}>
            <div className={styles["top-member"]}>
              <Image src={NIST} alt={t("common:nist")} />
              <p>{t("common:nist")}</p>
            </div>
            <div
              className={rusInnovationClass}
              onClick={handleRusInnovationClick}
            >
              <Image src={RUSInnovation} alt={t("common:fsi")} />
              <a href="http://www.fasie.ru/" target="_blank">
                {t("common:fsi")}
              </a>
            </div>
          </div>
        </GridContainer>
      </MaxWidth>
      <MaxWidth addclasses={[styles["second-line-wrapper"]]}>
        <GridContainer>
          <span>© 2010–{new Date().getFullYear()}. tevian</span>
          <span>
            {showSOUT && (
              <a className="T2" href="/sout.pdf" download>
                {t("common:sout")}
              </a>
            )}
            <Link className="T2" href="/terms">
              {t("common:terms")}
            </Link>
          </span>
          <span>
            design by{" "}
            <a href="https://www.dotorg.ru/" target="_blank">
              dotorg
            </a>
          </span>
        </GridContainer>
      </MaxWidth>
    </>
  );
};

export default Footer;
